import * as React from 'react';

import holidayHoursData from '../data/holidayHours.json';
import { InformationLayout } from '../components';

export const HolidayHoursPageInternal = ({ mainMarkdown }: typeof holidayHoursData) => {
  return (
    <InformationLayout title="Holiday Hours" mainMarkdown={mainMarkdown} header="Holiday Hours" />
  );
};

export default () => <HolidayHoursPageInternal {...holidayHoursData} />;
